
@import '../../styles/variables'
.cw-page-news
  .cw-area-headline
    background-color: $color-yellow
    text-align: center
    padding-top: 150px
    padding-bottom: 50px
    p
      font-size: 1.5em
